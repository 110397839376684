import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import MySwiper from '../components/Swiper'
import WebriQForm from '@webriq/gatsby-webriq-form'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'


class IndexPage extends React.Component {
 
    
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div>
          <p>This is the homepage</p>
          <Link to="/blog">Go to Blog</Link>
          <br />
          <br />
          <h1>Sample Swiper</h1>
          <br />
          <MySwiper slidesperview={3}>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/145698/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2203755/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/362271/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/3657445/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/385548/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/325867/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/1118905/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2270935/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/1252081/400x400" alt=""/></div>
            <div><LazyLoadImage effect="blur" className="img-fluid" src="https://source.unsplash.com/collection/2411320/400x400" alt=""/></div>
          </MySwiper>
          <br />
          <br />
          <div className="body-content py-5">
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-6 mt-4">
                  <div className="contact-form">
                    <h3>Contact Us!</h3>
										<WebriQForm
											name="Home Contact"
											method="POST"
											id="contact"
											data-form-id="8c31fc28-6be4-4b37-9d8d-84ec332b52b0"
                      data-thankyou-url="/thank-you"
                      scriptSrc="https://pk3v3a4ale.execute-api.us-west-2.amazonaws.com/dev/js/initReactForms"
										>
											<div className="form-group">
                        <input
                          name="Full Name"
													type="text"
													className="form-control"
													id="Name"
													aria-describedby="Name"
													placeholder="Name"
												/>
											</div>
											<div className="form-group">
                        <input
                          name="email"
													type="email"
													className="form-control"
													id="exampleInputEmail1"
													aria-describedby="Email"
													placeholder="Enter email"
												/>
											</div>
											<div className="form-group">
                        <input
                          name="subject"
													type="text"
													className="form-control"
													id="Subject"
													placeholder="Subject"
												/>
											</div>
											<div className="form-group">
                        <textarea
                          name="message"
													className="form-control"
													id="Message"
													rows="7"
													placeholder="Message"
												></textarea>
											</div>
											<div className="form-group">
												<div className="webriq-recaptcha mb-3" />
											</div>
											<button type="submit" className="btn btn-primary submit">
												Submit
											</button>
										</WebriQForm>
									</div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="contact-form">
                    <h3>Contact Us!</h3>
										<WebriQForm
                      method="POST"
                      data-form-id="5db3cebb-5d45-4712-bd47-0afd916f7b05"
                      name="Newsletter Form"
                      className="form-newsletter"
                    >
                      <div className="form-row">
                        <div className="col-12 mb-3">
                          <div className="form-group">
                            <input
                              className="form-control"
                              type="email"
                              name="Email Address"
                              id="email_address"
                              placeholder="info@yourmail.com"
                            />
                            <small className="text-dark form-text mt-2">
                              We will never share your email with anyone else.
                            </small>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <div className="webriq-recaptcha" />
                          </div>
                        </div>
              
                        <div className="col-12">
                          <div className="form-group">
                            <button
                              className="btn btn-primary hvr-shadow"
                              type="submit"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </WebriQForm>
									</div>
                </div>
							</div>
						</div>
					</div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
